import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo";
import 'bulma/css/bulma.css';
import '@fortawesome/fontawesome-free/css/all.css'
import ContactFormContainer from '../components/ContactForm/ContactForm.container';

const ContactPage = () => (
  <Layout>
    <SEO title="Contacto" />
    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, backgroundColor: 'rgb(245, 245, 245)'}}>
      <ContactFormContainer/>
    </div>
  </Layout>
)

export default ContactPage;
