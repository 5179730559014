import * as Yup from 'yup';

const validationDTO = Yup.object
({
   name: Yup.string()
   .max(55, 'El nombre debe poseer 15 caracteres o menos.')
   .required('Campo Requerido.'),
   phone: Yup.string()
   .min(9, 'El número de telefono debe poseer almenos 9 digitos.')
   .required('Campo Requerido.'),
   message: Yup.string()
   .max(500, 'El mensaje debe poseer 500 caracteres o menos.')
   .required('Campo Requerido.'),
   email: Yup.string()
   .email('El correo electrónico ingresado no es valido.')
   .required('Campo Requerido.'),
})

export default validationDTO;