import React from 'react';
import { Row, Col } from 'react-flexbox-grid';

const ContactFormComponent = ({ formik, isLoading, submitMessage }) => {

   return(
      <Col xs={11} sm={9} md={7} lg={5} className="card" style={{margin: '40px 0', padding: 0, borderRadius: 15}}>
         <Row>
            <Col xs={12} style={{padding: '20px 20px 10px 20px', borderBottom: '2px solid rgb(245, 245, 245)'}}>
               <h4 className="title is-4" style={{fontWeight: 500}}>Envianos un mensaje</h4>
            </Col>
            { Object.keys(submitMessage).length ?
               (<Col xs={12} 
                  style={{padding: '20px 30px 20px 30px', borderBottom: '2px solid rgb(245, 245, 245)', textAlign: 'center', backgroundColor: submitMessage.status == 'info' ? '#eef6fc' : '#feecf0'}}>
                  <strong className={`help is-size-6 is-${submitMessage.status}`}>
                     { submitMessage.text }
                  </strong>
                </Col>) : null
            }
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
               <Col xs={10} style={{margin: '30px 0 20px 0'}}>
                  <form onSubmit={formik.handleSubmit}>
                     <fieldset disabled={isLoading ? true : false}>
                        <div className="field">
                           <label htmlFor="name" className="label">Nombre</label>
                           <div className="control has-icons-left has-icons-right">
                              <input
                                 id="name"
                                 className={"input " + (formik.touched.name && formik.errors.name ? "is-danger" : formik.touched.name && !formik.errors.name ? "is-success" : "")}
                                 name="name"
                                 type="text"
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 value={formik.values.name}
                                 />
                              <span className="icon is-small is-left">
                                 <i className="fas fa-user"></i>
                              </span>
                              { formik.touched.name &&
                                 (<span className="icon is-small is-right">
                                    <i className={formik.touched.name && formik.errors.name ? "fas fa-exclamation-triangle" : "fas fa-check"}></i>
                                 </span>)
                              }
                           </div>
                           {formik.touched.name && formik.errors.name ? (
                              <p className="help is-danger">{formik.errors.name}</p>
                           ) : null}
                        </div>

                        <div className="field">
                           <label htmlFor="email" className="label">Email</label>
                           <div className="control has-icons-left has-icons-right">
                              <input
                                 id="email"
                                 className={"input " + (formik.touched.email && formik.errors.email ? "is-danger" : formik.touched.email && !formik.errors.email ? "is-success" : "")}
                                 name="email"
                                 type="text"
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 value={formik.values.email}
                                 />
                              <span className="icon is-small is-left">
                                 <i className="fas fa-envelope"></i>
                              </span>
                              { formik.touched.email &&
                                 (<span className="icon is-small is-right">
                                    <i className={formik.touched.email && formik.errors.email ? "fas fa-exclamation-triangle" : "fas fa-check"}></i>
                                 </span>)
                              }
                           </div>
                           {formik.touched.email && formik.errors.email ? (
                              <p className="help is-danger">{formik.errors.email}</p>
                           ) : null}
                        </div>
                     
                        <div className="field">
                           <label htmlFor="phone" className="label">Teléfono</label>
                           <div className="control has-icons-left has-icons-right">
                              <input
                                 id="phone"
                                 className={"input " + (formik.touched.phone && formik.errors.phone ? "is-danger" : formik.touched.phone && !formik.errors.phone ? "is-success" : "")}
                                 name="phone"
                                 type="text"
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 value={formik.values.phone}
                                 />
                              <span className="icon is-small is-left">
                                 <i className="fas fa-phone"></i>
                              </span>
                              { formik.touched.phone &&
                                 (<span className="icon is-small is-right">
                                    <i className={formik.touched.phone && formik.errors.phone ? "fas fa-exclamation-triangle" : "fas fa-check"}></i>
                                 </span>)
                              }
                           </div>
                           {formik.touched.phone && formik.errors.phone ? (
                              <p className="help is-danger">{formik.errors.phone}</p>
                           ) : null}
                        </div>

                        <div className="field">
                           <label htmlFor="message" className="label">Mensaje</label>
                           <div className="control has-icons-left has-icons-right">
                              <textarea
                                 id="message"
                                 className={"textarea has-fixed-size " + (formik.touched.message && formik.errors.message ? "is-danger" : formik.touched.message && !formik.errors.message ? "is-success" : "")}
                                 name="message"
                                 type="text"
                                 onChange={formik.handleChange}
                                 onBlur={formik.handleBlur}
                                 value={formik.values.message}
                                 />
                              <span className="icon is-small is-left">
                                 <i className="fas fa-message"></i>
                              </span>
                           </div>
                           {formik.touched.message && formik.errors.message ? (
                              <p className="help is-danger">{formik.errors.message}</p>
                           ) : null}
                        </div>
                     </fieldset>
                     <div className="control is-grouped-centered" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 30}}>
                        <button
                           style={{width: 200}}
                           type="submit" 
                           disabled={Object.keys(formik.errors).length || isLoading ? true : false}
                           className={"button is-dark " + (isLoading && "is-loading")}>
                           Enviar
                        </button>
                     </div>
                  </form>
               </Col>
            </div>
         </Row>
      </Col>
   )
}


export default ContactFormComponent;