import React, { useState } from 'react';
import axios from 'axios';
import ContactFormComponent from './ContactForm.component';
import { useFormik } from 'formik';
import validationDTO from './formDTO';

const ContactFormContainer = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [submitMessage, setSubmitMessage] = useState({})
   const formik = useFormik({
      initialValues: {
         name: '',
         email: '',
         phone: '',
         message: '',
      },
      validationSchema: validationDTO,
      onSubmit: (values) => submitForm(values)
   });

   const submitForm = async (data) => {
      setIsLoading(true);
      try{
         const req = await axios.post('https://us-central1-rs-security-6b432.cloudfunctions.net/sendEmail', data);
         if(req.status === 200){
            setSubmitMessage({
               status: 'info',
               text: 'Su mensaje fue recibido exitosamente, lo contactaremos a la brevedad.'
            })
         }else{
            throw 'Ocurrio un error en el servidor';
         }

      }catch(e){
         setSubmitMessage({
            status: 'danger',
            text: 'Ocurrio un error inesperado, por favor vuelva a intentarlo.'
         })
      }
      finally{
         setIsLoading(false);
         formik.resetForm({});
      }
   }

   return(
      <ContactFormComponent
         submitMessage={submitMessage}
         isLoading={isLoading}
         formik={formik}/>
   )
}


export default ContactFormContainer;